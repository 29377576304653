<template>
  <div class="info">
    <el-row>
      <el-col :span="16">
        <span>科类:</span>
        <span class="info_2">{{ form.is_wenli == 1 ? '文科' : '理科' }}</span>
        <span>分数:</span>
        <span class="info_2">{{ form.score }}分</span>
        <span>位次:&nbsp;</span>
        <span class="info_2">{{ scoreList[0] }}</span>
        <span>我的同位分：</span>
        <span class="info_3">{{ scoreList[1] }}分</span>
        <span class="info_4">/22年</span>
        <span class="info_3">{{ scoreList[2] }}分</span>
        <span class="info_4">/21年</span>
        <span class="info_3">{{ scoreList[3] }}分</span>
        <span class="info_4">/20年</span>
      </el-col>

      <el-col :span="7" :offset="1">
        <span>填报批次：</span>
        <span class="select">
          <el-select v-model="form.batch" placeholder="请选择" class="searchBatch" size="mini" disabled>
            <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: { batch: Number, score: Number, is_wenli: Number },
  data() {
    return {
      form: {
        score: 0,
        is_wenli: 0,
        batch: 0,
      },
      test: "",
      scoreList: [0, 0, 0, 0],
      batchList: [
        { id: 1, name: "本科一批", code: 1, },
        { id: 2, name: "本科二批", code: 2 },
        { id: 3, name: "高职高专批", code: 3 },
      ],
    };
  },
  mounted() {
    this.form.score = this.score;
    this.form.batch = this.batch;
    this.form.is_wenli = this.is_wenli;
    this.getEqualList();
  },
  methods: {
    // 获取等同分
    getEqualList() {
      this.$fecth
        .post("score_equal/getEqualScore", {
          is_wenli: this.form.is_wenli,
          score: this.form.score,
        })
        .then((res) => {
          res = res.data;
          this.scoreList = res;
        });
    },
  },
};
</script>

<style scoped lang='less'>
.info {
  padding: 3px 7px;
  font-size: 16px;
  position: relative;
  background: white;

  span {
    display: inline-block;
  }

  margin: 20px 0;

  .info_2 {
    color: #1787e0;
    margin-right: 25px;
  }

  .info_3 {
    color: #ff6600;
    margin-left: 15px;
  }

  .info_4 {
    color: #a5a3a2;
  }
}
</style>
