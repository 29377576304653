<template>
  <div>
    <div class="info">
      <el-row>
        <el-col>
          <span>科类:</span>
          <span class="info_2">{{ this.$route.query.is_wenli == 1 ? '文科' : '理科' }}</span>
          <span>分数:</span>
          <span class="info_2">{{ this.$route.query.score }}分</span>
          <span>位次:&nbsp;</span>
          <span class="info_2">{{ ranking }}</span>
          <span>我的同位分:</span>
          <span class="info_3">{{ score1 }}分</span>
          <span class="info_4">/22年</span>
          <span class="info_3">{{ score2 }}分</span>
          <span class="info_4">/21年</span>
          <span class="info_3">{{ score3 }}分</span>
          <span class="info_4">/20年</span>
        </el-col>
      </el-row>

    </div>
    <el-divider class="divider_CP"></el-divider>
    <div>
      <span style="font-size: 16px; font-weight: bold;color:#000000">{{ collegeData.name }}</span>
      <span style="margin-left: 25px;color:#75787b">所在地:{{ collegeData.city_name }}</span>
      <span style="margin-left: 25px;color:#75787b">招生代号:{{ collegeData.school_id }}</span>
      <span style="margin-left: 25px;color:#75787b">录取规则:{{ collegeData.lqgz }}</span>
    </div>
    <el-row style="margin-top: 25px" :gutter="20" class="plan_tab1">
      <el-col :span="12">
        <el-row type="flex" align="middle" style="margin-bottom: 19px">
          <el-col :span="4" class="box-title">
            <el-popover v-model="collegeData.visibles" popper-class="popover-f" placement="bottom" trigger="manual">
              <div class="popover-box">
                <p class="box-title">填报为</p>
                <p v-for="h in len" :key="h" class="box-item" @click="chooseSchool(collegeData, h - 1)">
                  平行志愿{{ word[h - 1] }}
                </p>
              </div>
              <div v-if="record.indexOf(collegeData.select_code) > -1" slot="reference" class="btn schoolBtn"
                @click="changeAlert(collegeData)">
                志愿{{ word[record.indexOf(collegeData.select_code)] }}
              </div>
              <div slot="reference" v-else class="btn " @click="changeAlert(collegeData)">
                填报为
              </div>
            </el-popover>
          </el-col>
          <el-col :span="3"> 服从调剂 </el-col>
          <el-col :span="5">
            <el-radio-group v-model="radio" @change="getTiaoji">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-table :data="planData" class="tab_plan" height="325"
          :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
          :cell-style="{ background: 'white', padding: '3px 0' }" style="width: 100%" border v-loading='loading'>
          <el-table-column label="今年招生计划">
            <el-table-column prop="specialty_id" label="代码" width="49px" align="center">
              <template slot-scope="scope">
                {{ scope.row.specialty_id | interpo }}
              </template>
            </el-table-column>
            <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="remarks" label="专业备注" show-overflow-tooltip align="center" width="60">
            </el-table-column>
            <el-table-column prop="plan_num" label="招生计划" align="center" width="60">
            </el-table-column>
            <el-table-column prop="tuition" label="学费/年" align="center" width="100"> </el-table-column>
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <div v-if="record.indexOf(collegeData.select_code) > -1">
                  <el-button v-if="spList.indexOf(scope.row.specialty_id) > -1" @click="chooseSpecialty(scope.row)"
                    size="mini" type="primary">
                    已选择
                  </el-button>
                  <el-button v-else @click="chooseSpecialty(scope.row)" size="mini">
                    填报
                  </el-button>
                </div>
                <div v-else>
                  <el-button @click="chooseSpecialty(scope.row)" size="mini">填报</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <MojarOld v-if="equalList" :school_id='collegeData.school_id' :select_code="collegeData.select_code"
          :equalList=equalList :searchKey='searchKey'> </MojarOld>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MojarOld from "./MojarOld";

export default {
  props: ["collegeData", "len", 'table', 'record',],
  name: "",
  components: {
    MojarOld,
  },
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九",'十','十一','十二'],
      planData: [],
      loading: true,
      searchKey: "",
      ranking: 0,
      score1: 0,
      score2: 0,
      score3: 0,
      radio: true,
      equalList: [],
      spList: [],
      spData: []
    };
  },
  mounted() {

    let index = this.record.indexOf(this.collegeData.select_code)

    if (index > -1) {
      //专业数据
      this.spData = this.table[index].specialty;

      //专业index
      this.table[index].specialty.forEach(item => {
        this.spList.push(item.specialty_id);
      })
    }

    this.radio = this.collegeData.tiaoji;
    this.getPlan();
    this.getEqualScore();
  },

  methods: {
    //获取数据
    getPlan() {
      if (this.collegeData.select_code) {
        this.loading = true;
        this.$fecth
          .post("volunteer/getPlan", {
            select_code: this.collegeData.select_code,
            batch: this.$route.query.batch
          })
          .then((res) => {
            res = res.data;
            this.planData = res;
            this.loading = false;
          });
      }
    },

    chooseSchool(item, i) {
      item.visibles = false;
      item.tiaoji = 1;
      //判断是否选择过此学校。如果有就删掉
      let ttt = this.record.indexOf(item.select_code);
      if (ttt > -1) {
        this.record[ttt] = ''
      }
      //判断此位置是否填报过。如果有。覆盖
      this.table[i].college = item
      this.table[i].specialty = []
      this.record[i] = item.select_code
      this.spList = []
      this.spData = []
    },

    //填报为
    changeAlert(item) {
      item.visibles = !item.visibles;
      this.$forceUpdate();
    },

    //选择专业
    chooseSpecialty(item) {
      let sp_id = this.spList.indexOf(item.specialty_id);
      if (sp_id > -1) {
        this.spList.splice(sp_id, 1);
        this.spData.splice(sp_id, 1)
      } else {
        let splen = this.spList.length;
        if (splen == 5) {
          this.$alert("每个志愿最多填报5个专业！", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        } else {
          this.spList.push(item.specialty_id);
          this.spData.push(item);
        }
      }
      this.$forceUpdate();
    },

    save() {
      setTimeout(() => {
        this.$emit("close");
      }, 1000);
    },

    //获取等同分
    getEqualScore() {
      this.$fecth
        .post("score_equal/getEqualScore", {
          is_wenli: this.$route.query.is_wenli,
          score: this.$route.query.score,
        })
        .then((res) => {
          res = res.data
          this.equalList = res;
          this.ranking = res[0];
          this.score1 = res[1];
          this.score2 = res[2];
          this.score3 = res[3];
        });
    },

    //调剂
    getTiaoji() {
      this.collegeData.tiaoji = this.radio;
    },

  },
};
</script>

<style scoped lang='less'>
.btn {
  text-align: center;
  border-radius: 20px;
  border: 1px solid #409eff;
  color: #8c939d;
  width: 68px;
  margin: 10px auto 15px;
  font-size: 12px;
  line-height: 24px;
}

.schoolBtn {
  border-radius: 4px;
  background: #409eff;
  color: white;
}

.info {
  font-size: 14px;
  position: relative;

  span {
    display: inline-block;
  }

  .info_1 {
    margin-left: 15px;
    color: #ff6600;
    margin-right: 30px;
  }

  .info_2 {
    color: #2871fb;
    margin-right: 25px;
  }

  .info_3 {
    color: #ff6600;
    margin-left: 15px;
  }

  .info_4 {
    color: #75787b;
  }
}

//选择院校
.popover-f {
  width: 70px;
  background-color: #3d3d3c;
  color: white;
  min-width: 0;

  .popper__arrow {
    display: none;
  }

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    padding: 10px 0;
    font-size: 14px;
  }
}
</style>

<style lang="less" >
.tab_plan {

  .el-table td,
  .el-table th {
    padding: 0px;
  }
}

.divider_CP {
  margin: 10px 0;
}
</style>