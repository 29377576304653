<template>
  <div>
    <div class="search-box">
      <div class="tabs">
        <el-row>
          <el-col :span="3"><span class="title">办学类型></span> </el-col>
          <el-col :span="21">
            <span :class="{ act: check.degree === null }" @click="choose('degree', null, 0)">全部</span>
            <span :class="{ act: check.degree === 0 }" @click="choose('degree', 0, 0)">普通本科</span>
            <span :class="{ act: check.degree === 1 }" @click="choose('degree', 1, 0)">专科（高职）</span>
          </el-col>
        </el-row>
        <el-divider class="search_line"></el-divider>
        <el-row>
          <el-col :span="3">
            <span class="title">院校性质></span>
          </el-col>
          <el-col :span="21">
            <span :class="{ item: true, act: check.nature_id === null }" @click="choose('nature_id', null, 0)">全部</span>
            <span :class="{ item: true, act: check.nature_id === 1 }" @click="choose('nature_id', 1, 0)">公办</span>
            <span :class="{ item: true, act: check.nature_id === 2 }" @click="choose('nature_id', 2, 0)">民办</span>
            <span :class="{ item: true, act: check.nature_id === 3 }" @click="choose('nature_id', 3, 0)">中外合作办学</span>
            <span :class="{ item: true, act: check.nature_id === 4 }" @click="choose('nature_id', 4, 0)">内地与港澳台地区合作办学</span>
          </el-col>
        </el-row>
        <el-divider class="search_line"></el-divider>

        <el-row>
          <el-col :span="3"><span class="title">所属地区></span></el-col>
          <el-col :span="21">
            <span :class="{ item: true, act: check.city_id.length === 0 }" @click="choose('city_id', -1, 1)">全部</span>
            <span v-for="(item, i) in cityList" :key="i" :class="{ act: check.city_id.indexOf(item.city_code) > -1 }" @click="choose('city_id', item.city_code, 1)">{{ item.city }}</span>
          </el-col>
        </el-row>
        <el-divider class="search_line"></el-divider>

        <el-row>
          <el-col :span="3"><span class="title">院校类型></span></el-col>
          <el-col :span="21">
            <span :class="{ item: true, act: check.school_type_id.length === 0 }" @click="choose('school_type_id', -1, 1)">全部</span>
            <span v-for="(item, i) in schoolTypeList" :key="i" :class="{ act: check.school_type_id.indexOf(item.id) > -1 }" @click="choose('school_type_id', item.id, 1)">{{ item.name }}</span>
          </el-col>
        </el-row>
        <el-divider class="search_line"></el-divider>

        <el-row>
          <el-col :span="3"><span class="title">招生单位></span></el-col>
          <el-col :span="21">
            <span v-for="(item, i) in recruitUnitList" :key="i" :class="{ act: check.specific_code.indexOf(item.id) > -1 }" @click="choose('specific_code', item.id, 1)">
              {{ item.name }}
            </span>
          </el-col>
        </el-row>
        <el-divider class="search_line"></el-divider>

        <el-row>
          <el-col :span="3"><span class="title">院校特色></span></el-col>
          <el-col :span="21">
            <span :class="{ item: true, act: check.schoolTipId.length === 0 }" @click="choose('schoolTipId', -1, 1)">全部</span>
            <span v-for="(item, i) in schoolTipList" :key="i" :class="{
              item: true,
              act: check.schoolTipId.indexOf(item.id) > -1,
            }" @click="choose('schoolTipId', item.id, 1)">{{ item.name }}
            </span>
          </el-col>
        </el-row>

      </div>

      <div class="demo-input-suffix">
        搜索大学：
        <el-input placeholder="请输入意向院校" style="width:300px;margin-right:110px" v-model="check.input1" size="mini" @keyup.enter.native="doSearch">
        </el-input>
        搜索专业：
        <el-input placeholder="请输入意向专业" style="width:300px" v-model="check.input2" size="mini" @keyup.enter.native="doSearch">
        </el-input>
        <span style="float:right;background:#2b7bf3;color:white;padding: 3px 12px;" size="mini" @click="doSearch">搜索</span>
      </div>
    </div>
  </div>
</template>

<script>
import cityList from "@/assets/data/city_data";

export default {
  name: "",
  data() {
    return {
      cityList: cityList,
      recruitUnitList: [
        {
          id: 2,
          name: "较高收费",
        },
        {
          id: 7,
          name: "其他单列",
        },
        {
          id: 4,
          name: "异地校区",
        },
        {
          id: 5,
          name: "医护类",
        },
        {
          id: 3,
          name: "少数民族",
        },
      ],
      schoolTypeList: [
        {
          id: "17",
          name: "财经类",
        },
        {
          id: "18",
          name: "理工类",
        },
        {
          id: "19",
          name: "农林类",
        },
        {
          id: "20",
          name: "综合类",
        },
        {
          id: "21",
          name: "政法类",
        },
        {
          id: "22",
          name: "师范类",
        },
        {
          id: "23",
          name: "语言类",
        },
        {
          id: "24",
          name: "医药类",
        },
        {
          id: "26",
          name: "民族类",
        },
        {
          id: "27",
          name: "艺术类",
        },
        {
          id: "28",
          name: "体育类",
        },
        {
          id: "29",
          name: "军事类",
        },
      ],
      schoolTipList: [
        {
          id: "11",
          name: "C9",
          type: "0",
        },
        {
          id: "5",
          name: "985",
          type: "0",
        },
        {
          id: "6",
          name: "211",
          type: "0",
        },
        {
          id: "7",
          name: "双一流A",
          type: "0",
        },
        {
          id: "12",
          name: "双一流B",
          type: "0",
        },
        {
          id: "13",
          name: "国家示范",
          type: "0",
        },
        {
          id: "14",
          name: "国家骨干",
          type: "0",
        },
        {
          id: "15",
          name: "双高计划",
          type: "0",
        },
      ],
      check: {
        degree: null,
        city_id: [],
        nature_id: null,
        specific_code: [2, 7, 4, 5, 3],
        school_type_id: [],
        schoolTipId: [],
        input1: "",
        input2: "",
      },
    };
  },
  computed: {},
  
  methods: {
    //选择条件
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.$emit("onSearch", this.check);
    },
    doSearch() {
      this.$emit("onSearch", this.check);
    },
  },
};
</script>

<style scoped lang='less'>
.search-box {
  margin: 20px 0;
  .college-btn {
    padding: 3px 30px;
    font-size: 15px;
    margin-left: 56px;
    border-radius: 3px;
  }

  .tabs {
    color: #666666;
    letter-spacing: 2px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 15px;

    .title {
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    span {
      display: inline-block;
      padding: 0 6px;
      line-height: 22px;
      margin-bottom: 3px;
      margin-right: 6px;

      &:hover {
        background: #2b7bf3;
        color: white;
      }
    }
  }
  .act {
    background-color: #2b7bf3;
    color: #ffffff;
  }
}

.search_line {
  margin: 7px 0;
}
</style>

