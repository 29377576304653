<template>
  <div class="info">
    <equal_score :batch="batch" :is_wenli="is_wenli" v-if="score" :score="score"></equal_score>

    <div class="report">
      <div class="nav">
        <div :class="{ act: this.nav_id == 1 }" @click="nav_id = 1">
          <p>提交方案</p>
        </div>
        <div :class="{ act: this.nav_id == 2 }" @click="nav_id = 2">
          <p>调整方案</p>
        </div>
        <div :class="{ act: this.nav_id == 3 }" @click="nav_id = 3">
          <p>回复方案</p>
        </div>
      </div>
      <div v-if="nav_id == 1">
        <volunteer_user :tableData=tableData :remarks=remarks @ChangeNav="ChangeNav"></volunteer_user>


      </div>
      <div v-if="nav_id == 2" style="padding:15px">
        <div v-if="collegeList && enroll_score">
          <conditional-search @onSearch='onSearch' />
          <volunteer v-for="(item, i) in collegeList" :key="i" :item="item" :school_child='school_child'
            :enroll_score='enroll_score' :table="table" :record=record :issss="issss" @close="test">
          </volunteer>
          <!-- 分页 -->
          <div class="main">
            <template>
              <div style="text-align: center; margin-bottom: 20px">
                <el-pagination @current-change="handleCurrentChange" :page-size="this.pageSize"
                  layout=" prev, pager, next" :total="this.total">
                </el-pagination>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="nav_id == 3">
        <volunteer_reply :table="table" :remarks="remarks"> </volunteer_reply>
      </div>
    </div>
  </div>
</template>

<script>
import equal_score from "./EqualScore.vue";
import volunteer from "./volunteer.vue";
import volunteer_reply from "./VolunteerReply.vue";
import volunteer_user from "./VolunteerUser.vue";
import ConditionalSearch from "./components/ConditionalSearch.vue";
export default {
  name: "",
  components: {
    equal_score,
    volunteer,
    volunteer_reply,
    volunteer_user,
    ConditionalSearch,
  },
  data() {
    return {
      score: '',
      is_wenli: 1,
      batch: 1,
      nav_id: 1,
      table: [],
      remarks: "",
      tableData: [],
      collegeList: [],
      school_child: [],
      enroll_score: null,
      record: [],
      total: 0,
      issss: 0,
      // 分页
      pageNum: 1,
      pageSize: 10,
    };
  },

  mounted() {
    this.batch = Number(this.$route.query.batch);
    this.is_wenli = Number(this.$route.query.is_wenli);
    this.score = Number(this.$route.query.score);
    this.getReport();
    this.getList();
    this.getOldCityScore()
  },
  methods: {
    getReport() {
      this.$fecth
        .post("reviewed/getReport", {
          report_id: this.$route.query.report_id,
        })
        .then((res) => {
          const { code, msg, data } = res;
          if (code == 200) {
            this.remarks = data.remarks;
            this.table = JSON.parse(data.table);
            this.tableData = JSON.parse(data.table);
            this.table.forEach((item, i) => {
              this.record[i] = item.college.select_code
            })
          } else {
            this.showTips(0, msg);
          }
        });
    },
    ChangeNav(e) {
      this.nav_id = e
    },
    onSearch(item) {
      this.check = item;
      if (this.check.input1 || this.check.input2) {
        this.getLists();
      } else {
        this.getList();
      }
    },
    getList() {
      this.$fecth
        .post("reviewed/GetSchoolList", {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          score: this.score,
          is_wenli: this.is_wenli,
          batch: this.batch,
        })
        .then((res) => {
          this.collegeList = this.getDengji(res.data);
          this.school_child = res.data.school_child;
          this.total = res.data.count;
        });
    },

    //搜索框搜索
    getLists() {
      if (this.batch == 0) {
        return;
      }
      let str1 = "volunteer/collegeSearch";
      let str2 = "volunteer/getMajorFirst";
      let url = "";
      let keyword = '';
      if (this.check.input1) {
        keyword = this.check.input1
        url = str1;
      } else {
        url = str2;
        keyword = this.check.input2
      }
      this.$fecth
        .post(url, {
          keyword: keyword,
          score: this.score,
          is_wenli: this.is_wenli,
          batch: this.batch,
        })
        .then((res) => {
          this.collegeList = this.getDengji(res.data);
          this.school_child = res.data.school_child;
          this.total = res.data.count;
        });
    },
    //根据批次往年录取分数线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLine", {
          is_wenli: this.is_wenli,
          batch: this.batch,
        })
        .then((res) => {
          res.data.splice(0,1);
          this.enroll_score = res.data;
        });
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },

    //获取等级
    getDengji(val) {
      val.lists.forEach((item) => {
        let num = Number(this.score) + 5 - Number(item.entry_score);
        item.dengji = Math.ceil(num / 2.5);
      });
      return val.lists;
    },
    test(item) {
      this.table = item
      this.issss = this.issss + 1
      console.log(this.table)
      this.$forceUpdate()
    }
  },
};
</script>

<style scoped lang='less'>
.info {
  background: white;
  overflow: hidden;
  margin-top: 10px;
}

.report {
  .nav {
    margin-left: 15px;

    div {
      width: 219px;
      border: 1px solid #459df6;
      color: black;
      padding: 8px 57px;
      display: inline-block;
      margin-right: 20px;
      border-radius: 3px 3px 0 0;
    }

    .act {
      color: white;
      background: #459df6;
    }
  }
}
</style>
