<template>
  <div class="oldData">
    <el-row type="flex" align="middle" style="margin-bottom: 11px;">
      <el-input placeholder="可输入专业名称" v-model="search" size="mini" clearable style="width:300px" type="primary">
        <template slot="append">搜索</template>
      </el-input>
    </el-row>
    <el-tabs v-model="activeName" v-loading='loading'>
      <el-tab-pane label="2022年专业数据" name="first">
        <mojar-score v-if="line && equalList" :tableData=tableData :search=search :line='Number(line[0])'
          :score='Number(equalList[1])' />
      </el-tab-pane>
      <el-tab-pane label="2021年专业数据" name="second">
        <mojar-score v-if="line && equalList" :tableData=tableData1 :search=search :line='Number(line[1])'
          :score='Number(equalList[2])' />
      </el-tab-pane>
      <el-tab-pane label="2020年专业数据" name="third">
        <mojar-score v-if="line && equalList" :tableData=tableData2 :search=search :line='Number(line[2])'
          :score='Number(equalList[3])' />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MojarScore from "./MojarScore.vue";
import { mapState } from "vuex";
export default {
  components: { MojarScore },
  name: "",
  computed: { ...mapState(["ExpertInfo"]) },
  props: ["select_code", "school_id", "searchKey", "equalList"],
  data() {
    return {
      loading: true,
      activeName: "first",
      tableData: [],
      tableData1: [],
      tableData2: [],
      filtersdata: [{ text: "王", value: "王" }],
      search: "",
      batch: 0,
      line: null,
      score: 40,
    };
  },
  watch: {
    school_id() {
      this.getData();
    },
    searchKey() {
      this.search = this.searchKey;
      this.$forceUpdate();
    },
  },
  created() {
    this.getData();
    this.getLine();
  },
  methods: {
    refersh() {
      this.tableData = [];
      this.tableData1 = [];
      this.tableData2 = [];
      this.loading = true;
    },
    getData() {
      this.refersh();
      this.batch = this.$route.query.batch
      this.$fecth
        .post("volunteer/getOldPlan", {
          select_code: this.select_code,
          batch: this.batch,
        })
        .then((res) => {
          res = res.data;
          this.tableData = res.first;
          this.tableData1 = res.second;
          this.tableData2 = res.three;
          this.loading = false;
        });
    },

    //获取往年批次线
    getLine() {
      this.$fecth
        .post("volunteer/scoreOldLine", {
          is_wenli: this.$route.query.is_wenli,
          batch: this.$route.query.batch
        })
        .then((res) => {
          res = res.data
          this.line = res;
        });
    },
  },
};
</script>

<style  lang='less'>
.oldData {
  .el-tabs__header {
    margin: 0;
    border: 1px solid #ebeef5;
    border-bottom: 0;
  }

  .el-tabs__item {
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #555555;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background: #459df5;
    border: 1px solid #459df5;
    color: white;
  }

  .el-tabs__item.is-active {
    background: #459df5;
    color: white;
  }

  .el-tabs__item:hover {
    background: #459df5;
    color: white;
  }

  .el-input--mini .el-input__inner {
    height: 26px;
    line-height: 26px;
    border: 1px solid #459df5;
  }

  .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
  .el-tabs--bottom .el-tabs__item.is-top:last-child,
  .el-tabs--top .el-tabs__item.is-bottom:last-child,
  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding-right: 20px;
  }

  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
  .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
  }

  .el-tabs__nav-wrap::after {
    height: 0;
  }
}
</style>
