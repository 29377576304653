<template>
  <div>
    <user_data />
    <volunteer_report />
  </div>
</template>

<script>
import user_data from "./UserData";
import volunteer_report from "./volunteer/index.vue";
export default {
  name: "",
  components: {
    user_data,
    volunteer_report,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang='less'>
</style>
