<template>
  <div>
    <table class="tab1" border="1px">
      <!--   表头-->
      <vHead />
      <tbody>
        <tr>
          <td rowspan="3" style="color:gray" v-if="!word[item.dengji - 1]">无法推荐 </td>
          <td rowspan="3" v-else>平行志愿{{ word[item.dengji - 1] }}</td>
          <td rowspan="3" class="school_data">
            <p>{{ item.name }}【{{ item.school_id }}】 <span v-if="item.degree != null">*</span> </p>
            <p>办学性质:{{ item.nature }}录取规则:{{ item.lqgz }} </p>
            <p v-if="item.city_name">所在地:{{ item.city_name }}</p>
            <p v-if="item.school_tip">院校标签：{{ item.school_tip }}</p>
            <p v-if="item.td_ranking_1">上年投档最低位次:{{ item.td_ranking_1 }}</p>
          </td>
          <td rowspan="3">{{ item.plan_num }}</td>
          <!--                    年份-->
          <td v-if="key">{{ key.year }}</td>

          <td v-else colspan="10">-</td>
          <!--计划数 -->
          <td v-if="key"> {{ key.plan_num }} </td>
          <!--  录取数-->
          <td v-if="key"> {{ key.matriculate }} </td>
          <!--  最高分-->
          <td v-if="key">{{ key.max_score }}</td>
          <!--  平均分-->
          <td v-if="key">{{ key.mean_score }}</td>
          <!--  平均分位次-->
          <td v-if="key" style="color: #0f81fd">{{ key.mean_ranking }}</td>
          <!--  平均分线差-->
          <td v-if="key"> {{ key.mean_score - enroll_score["0"].score | Zero }} </td>
          <!--  最低分-->
          <td v-if="key"> {{ key.min_score }}</td>
          <!--  最低分位次-->
          <td v-if="key" style="color: red"> {{ key.ranking }}</td>
          <!--  最低分线差-->
          <td v-if="key">{{ key.min_score - enroll_score["0"].score | Zero }} </td>

          <td rowspan="3">
            <el-popover v-model="item.visible" popper-class="popover-f" placement="bottom" trigger="manual">
              <div class="popover-box">
                <p class="box-title">填报为</p>
                <p v-for="h in len" :key="h" class="box-item" @click="chooseSchool(item, h - 1)">
                  平行志愿{{ word[h - 1] }}
                </p>
              </div>
              <div v-if="record.indexOf(item.select_code) > -1" slot="reference" class="btn schoolBtn"
                @click="changeAlert(item)">
                志愿{{ word[record.indexOf(item.select_code)] }}
              </div>
              <div slot="reference" v-else class="btn " @click="changeAlert(item)">
                填报为
              </div>
            </el-popover>

            <div class="text-c color-7">
              <div class="btn" @click="getSpecialty(item)">
                查看专业
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="key1" class="bg_line">
          <td v-if="key1">{{ key.year - 1 }}</td>
          <td v-if="key1"> {{ key1.plan_num }} </td>
          <!--  录取数-->
          <td v-if="key1"> {{ key1.matriculate }} </td>
          <!--  最高分-->
          <td v-if="key1">{{ key1.max_score }}</td>
          <!--  平均分-->
          <td v-if="key1">{{ key1.mean_score }}</td>
          <!--  平均分位次-->
          <td v-if="key1" style="color: #0f81fd">{{ key1.mean_ranking }}</td>
          <!--  平均分线差-->
          <td v-if="key1"> {{ key1.mean_score - enroll_score["1"].score | Zero }} </td>
          <!--  最低分-->
          <td v-if="key1"> {{ key1.min_score }}</td>
          <!--  最低分位次-->
          <td v-if="key1" style="color: red"> {{ key1.ranking }}</td>
          <!--  最低分线差-->
          <td v-if="key">{{ key1.min_score - enroll_score["1"].score | Zero }} </td>
        </tr>
        <tr v-else class="bg_line">
          <td colspan="10">-</td>
        </tr>
        <tr v-if="key2">
          <td v-if="key2">{{ key.year - 2 }}</td>
          <td v-if="key2"> {{ key2.plan_num }} </td>
          <!--  录取数-->
          <td v-if="key2"> {{ key2.matriculate }} </td>
          <!--  最高分-->
          <td v-if="key2">{{ key2.max_score }}</td>
          <!--  平均分-->
          <td v-if="key2">{{ key2.mean_score }}</td>
          <!--  平均分位次-->
          <td v-if="key2" style="color: #0f81fd">{{ key2.mean_ranking }}</td>
          <!--  平均分线差-->
          <td v-if="key2"> {{ key2.mean_score - enroll_score["2"].score | Zero }} </td>
          <!--  最低分-->
          <td v-if="key2"> {{ key2.min_score }}</td>
          <!--  最低分位次-->
          <td v-if="key2" style="color: red"> {{ key2.ranking }}</td>
          <!--  最低分线差-->
          <td v-if="key2">{{ key2.min_score - enroll_score["2"].score | Zero }} </td>
        </tr>
        <tr v-else>
          <td colspan="10">-</td>
        </tr>
      </tbody>
    </table>

    <el-dialog :visible.sync="dialogVisible" class="mojarDialog" width="1200px" @change="RefreshTable">
      <Mojar :collegeData="collegeData" v-if="dialogVisible" :table="table" :record=record :len="len"
        @Refresh="RefreshTable" @close="CloseDiaLog" />
    </el-dialog>
  </div>
</template>

<script>
import vHead from "./components/v_head";
import Mojar from "./components/Mojar/Mojar.vue";
export default {
  name: "",
  props: ['item', 'school_child', 'enroll_score', 'table', 'record', 'issss'],
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", '十', '十一', '十二'],
      yearList: [2023, 2022, 2021],
      len: 12,
      score: 580,
      key: [],
      key1: [],
      key2: [],
      batch: 1,
      dialogVisible: false,
      collegeData: []
    };
  },
  components: {
    vHead, Mojar
  },
  filters: {
    Zero(e) {
      if (e > -100) {
        return e;
      } else {
        return "-";
      }
    },
  },
  watch: {
    issss() {
      this.$forceUpdate()
    }
  },


  created() {
    this.batch = this.$route.query.batch
    this.key = this.school_child[this.item.select_code + this.yearList[0]]
    this.key1 = this.school_child[this.item.select_code + this.yearList[1]]
    this.key2 = this.school_child[this.item.select_code + this.yearList[2]]
  },
  computed: {},
  methods: {
    //选择院校
    chooseSchool(item, i) {
      item.visible = false;
      item.tiaoji = 1;
      //判断是否选择过此学校。如果有就删掉
      let ttt = this.record.indexOf(item.select_code);
      if (ttt > -1) {
        this.record[ttt] = ''
      }
      //判断此位置是否填报过。如果有。覆盖
      this.table[i].college = item
      this.table[i].specialty = []
      this.record[i] = item.select_code
      this.$emit('close', this.table)
      this.$forceUpdate();
    },
    //查看专业
    getSpecialty(item) {
      this.collegeData = item;
      this.dialogVisible = true;
    },
    RefreshTable() {

    },

    CloseDiaLog() {
      this.dialogVisible = false;
    },


    changeAlert(item) {
      item.visible = !item.visible;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang='less'>
.tab1 {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  border-bottom: 0px;

  tbody {
    line-height: 28px;

    .bg_line {
      background: #f4f7fc;
    }
  }
}

.btn {
  border-radius: 20px;
  border: 1px solid #409eff;
  color: #8c939d;
  width: 68px;
  margin: 3px auto 3px;
  font-size: 12px;
  line-height: 24px;
}

.schoolBtn {
  border-radius: 4px;
  background: #409eff;
  color: white;
}

.popover-f {
  width: 70px;
  background-color: #3d3d3c;
  color: white;
  min-width: 0;

  .popper__arrow {
    display: none;
  }

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    padding: 10px 0;
    font-size: 14px;
  }
}
</style>
