<template>
  <div>
    <div class="table">
      <div v-for="(item, i) in table" :key="i">
        <table border>
          <tbody>
            <tr rowspan="7" class="th">
              <td rowspan="7" style="width:130px; background:white">
                <div class="px">
                  平行志愿{{ word[i] }}
                </div>
              </td>
              <td colspan="5"> <span class="name">{{ item.college.name }}</span>
              </td>
            </tr>
            <tr>
              <td class="th" style="width:100px">专业序号</td>
              <td class="th">专业代号/名称</td>
            </tr>
            <tr v-for="( val, key) in item.specialty" :key="key">
              <td>专业{{ word[key] }}</td>
              <td>[{{ val.specialty_id | interpo }}]{{ val.specialty_name }}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <el-input v-model="r_remarks[i]" placeholder="此处添加备注信息"></el-input>
        </div>
      </div>

      <div class="user">
        <div class="title">
          学员想法
        </div>
        <div class="remarks">
          {{ remarks }}
        </div>
      </div>
      <div class="user">
        <div class="title">
          老师回复
        </div>
        <el-input type="textarea" v-model="reply" style="margin-top:10px" />
      </div>
      <div>
        <el-button type="primary" size="mini" class="btn" @click="saveReply">确认回复</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['table', 'remarks'],
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九",'十','十一','十二'],
      reply: '',
      r_remarks: []
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    addRemarks(i) {
      this.r_remarks[i] = ''
      this.$forceUpdate()
    },
    saveReply() {
      let data = {
        report_id: this.$route.query.report_id,
        r_remarks: JSON.stringify(this.r_remarks),
        r_table: JSON.stringify(this.table),
        reply: this.reply,
      }
      this.$fecth.post('reviewed/reply', data).then(res => {
        console.log(res)
      })
    }
  },
};
</script>

<style scoped lang='less'>
.table {
  padding: 20px;

  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }
}

.info {
  background: white;
  overflow: hidden;
  margin-top: 10px;
}

.report {
  .nav {
    margin-left: 15px;

    div {
      width: 219px;
      border: 1px solid #459df6;
      color: black;
      padding: 8px 57px;
      display: inline-block;
      margin-right: 20px;
      border-radius: 3px 3px 0 0;
    }

    .act {
      color: white;
      background: #459df6;
    }
  }

  .user {
    margin-top: 20px;

    .remarks {
      border: 1px solid #dddddd;
      padding: 20px;
      margin-top: 10px;
      z-index: 2rem;
    }
  }

  .btn {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
