<template>
  <div class="table">
    <table border v-for="(item, i) in tableData" :key="i">
      <tbody>
        <tr rowspan="7" class="th">
          <td rowspan="7" style="width:130px; background:white">
            <div class="px">
              平行志愿{{ word[i] }}
            </div>
            <div class="edit">
              <el-button round size="mini" @click="eidt()">编辑</el-button>
            </div>
          </td>
          <td colspan="5"> <span class="name">{{ item.college.name }}</span>
          </td>
        </tr>
        <tr>
          <td class="th" style="width:100px">专业序号</td>
          <td class="th">专业代号/名称</td>
        </tr>
        <tr v-for="( val, key) in item.specialty" :key="key">
          <td>专业{{ word[key] }}</td>
          <td>[{{ val.specialty_id | interpo }}]{{ val.specialty_name }}</td>
        </tr>
      </tbody>
    </table>
    <div class="user">
      <div class="title">
        学员想法
      </div>
      <div class="remarks">
        {{ remarks }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tableData", "remarks"],
  name: '',
  data() {
    return {
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九",'十','十一','十二'],
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    eidt() {
      this.$emit('ChangeNav', 2)
    }
  },
};
</script>

<style scoped lang='less'>
.table {
  padding: 20px;
  padding-bottom: 150px;

  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }
}

.user {
  .remarks {
    border: 1px solid #dddddd;
    padding: 20px;
    margin-top: 10px;
    z-index: 2rem;
  }
}
</style>