<template>
  <div>
    <el-table height="297"
      :data="tableData.filter(data => !search || data.specialty_name.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%" :header-cell-style="{ background: 'white', color: '#555', padding: '3px 0' }"
      :cell-style="{ background: 'white', padding: '3px 0' }" border>
      <div slot="empty" class="empty">
        <span>该专业在本年度可能未在豫招生</span>
      </div>
      <el-table-column prop="specialty_name" label="专业名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="plan_num" label="计划" width='50' align="center">
        <template slot-scope="scope">
          {{ scope.row.plan_num | changeZero }}
        </template>
      </el-table-column>

      <el-table-column prop="max_score" label="最高分" show-overflow-tooltip width='70' align="center">
        <template slot-scope="scope">
          {{ scope.row.max_score | changeZero }}
        </template>
      </el-table-column>
      <el-table-column prop="mean_score" label="平均分" show-overflow-tooltip width='70' align="center">
        <template slot-scope="scope">
          {{ scope.row.mean_score | changeZero }}
        </template>
      </el-table-column>
      <el-table-column prop="mean_score" label="最低分" show-overflow-tooltip width='70' align="center">
        <template slot-scope="scope">
          {{ scope.row.min_score | changeZero }}
        </template>
      </el-table-column>
      <el-table-column prop="min_score" label="位次" show-overflow-tooltip width='70' align="center">
        <template slot-scope="scope">
          {{ scope.row.ranking | changeZero }}
        </template>
      </el-table-column>
      <el-table-column prop="min_score" label="线差" show-overflow-tooltip width='45' align="center">
        <template slot-scope="scope">
          {{ Number(scope.row.min_score) ? Number(scope.row.min_score) - Number(line) : '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="min_score" label="同位分差" show-overflow-tooltip width='50' align="center">
        <template slot-scope="scope">
          {{ Number(scope.row.min_score) ? score - Number(scope.row.min_score) : '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    tableData: Array,
    search: String,
    score: Number,
    line: Number,
  },
  data() {
    return {
      score1: 0,
      score2: 0,
      score3: 0,
    };
  },
};
</script>

<style scoped lang='less'>
</style>
